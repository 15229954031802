import React,{useEffect, useState, Component, useRef} from 'react';

import '../style.scss'
import  '../../apis/youtube'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ReplyIcon from '@mui/icons-material/Reply';

import '../script.js'
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CLoseIconSvg from '../pic/close.svg'
import Error from '../pic/error2.svg'
import Loadi from '../pic/loading.gif'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import Crop32Icon from '@mui/icons-material/Crop32';


import VolumeUp from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';


import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';

import Dialog from '../../body/Dialog';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VideoLong from '../../ads/1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot.mp4'
import { useNavigate,Link,useParams, useSearchParams } from 'react-router-dom';
import {CircularProgress} from '@mui/material';
import DataAds from '../../apis/ads.json'
import Box from '@mui/material/Box';


  
  var type="mid"
 

  
   let playerX
   var VideoEnd
   var AdEnded=false
 var ids_count=sessionStorage.getItem('ids_count') || 0
  let countwdownInterval 
  let emergency_ids=[]
  let like
  let videoOn=false
  var duration


  let noIds=[]
  var userAgent = window.navigator.userAgent;

  var time=0;
  var videoDuration=0
  var halfDuration=0
  let timeafterskipbtn=0
  let timeafterskipvid=0
  let player




  function VideoPlayerMid({videolist}){
   
   
 
    const [isplayed, setPlay]=React.useState(true)

    const [countdown, setCountdown] = React.useState(5);
    const [mobile, setMobile]=React.useState(false)


    const ad1=useRef(null)
    const ad2=useRef(null)

    const countDownDiv=useRef(null)
    const SkipBtnimg=useRef(null)

    const skipBtn=useRef(null)

    const {vidindex}=useParams()
    const [searchParams] = useSearchParams();
    const adindex = searchParams.get('ad');
    const adindex2 = searchParams.get('ad2');
    const skipp = searchParams.get('s');
    const ad2played = useRef(false);
    
    const skipLineText=useRef(null)
    const videoLoader=useRef(null)
    const skipLineTextNonSkip=useRef(null)
    const YTplayer=useRef(null)
    const progressBar=useRef(null)
    const adlayout=useRef(null)

    const ContentInfo=useRef(null)

    const Overlay=useRef(null)
    const Overlay2=useRef(null)

    const videoPlayer=useRef(null)
    const videoPlayerContainer=useRef(null)
    const videoOverlay=useRef(null)

    const isAd2Exists = useRef(adindex2 !== null && !isNaN(Number(adindex2)));
    

    emergency_ids=[]

    const imageRef=useRef()


   for(let key in videolist){

    emergency_ids.push(videolist[key].id.videoId)
   }


    
     
    
   let isplay=true




 const handleCountdown=(newValue)=>{
  setCountdown(newValue)
 }

 console.log(DataAds.ads)
 //DataAds.ads[adindex].url
  
 async function handleRandomizeAd(){ 

  let ads=[]
  let adsTr=[]
  ads=DataAds.ads.map((item,index)=>item.url)
  adsTr=DataAds.ads.map((item,index)=>item.descr)

  let adTemp 
  let adTrTemp 

 

    for(let i=ads.length-1;i>0; i--){
      let j = Math.floor(Math.random()*(i+1));
      adTemp=ads[i]
      ads[i]=ads[j]
      ads[j]=adTemp

      adTrTemp=adsTr[i]
      adsTr[i]=adsTr[j]
      adsTr[j]=adTrTemp
    }

 
      //eslint-disable-next-line
      sendResultFieldData({setResultFields: true, 2:String("atv: " + adsTr)})
    
      return [ads, adsTr]

}



async function startAdBlock(){

  let adsPlayed=[]
 
  const [ads, adsTr] = await handleRandomizeAd()

  console.log(ads)
 adlayout.current.style.display="flex"
 

  for(let i=0; i<ads.length; i++){
    try{
      ad1.current.src=ads[i]
    

      

       
      
      await new Promise((resolve, reject)=>{

        const AdVideoEnd=()=>{
           progressBar.current.style.transition="0s"
          ad1.current.removeEventListener('ended',AdVideoEnd)
          progressBar.current.style.width=0 + "%"
         
          adsPlayed.push(adsTr[i])
          //eslint-disable-next-line
          sendResultFieldData({setResultFields: true, 3:String("ads: " + adsPlayed)})

          resolve()
        }

        const onError = (error) => {
          ad1.current.removeEventListener('ended', AdVideoEnd);
          reject(error);
      };

      ad1.current.addEventListener('ended',AdVideoEnd)

      ad1.current.play()
      setTimeout(() => {
         progressBar.current.style.transition="1s linear"
      }, 100);
     

      })


    }
    
    catch(error){
      console.log(error)
      return false
    }
   
  }


closeAd()
return true

}




 const skipCountDown=(e)=>{

  if(playerX.getPlayerState()==1){
    
    
    playerX.pauseVideo()
    console.log(playerX.getPlayerState())
  }

    videoDuration=Math.round(e.target.duration)
    time=Math.round(e.target.currentTime)
    
    progressBar.current.style.width=(time*100)/videoDuration + "%"


  if(skipp==1){
  setCountdown(Math.round(5-Math.round(e.target.currentTime)))

  if(countdown==0){
    setCountdown(0)
    skipBtn.current.classList.add("BtnSkippEnd")
      skipLineText.current.style.display="flex"
      skipBtn.current.style.pointerEvents="auto"
        SkipBtnimg.current.style.display="none"
    countDownDiv.current.style.display="none"
  }
}


if(time>=videoDuration){
  if(!isAd2Exists.current || ad2played.current){
  //  closeAd()
    e.target.removeEventListener("timeupdate", skipCountDown)
  }
  else{
   // nextAd()
  }
   
 }

 }


 const resetSkipBtn=()=>{
    if(skipp==1){
    setCountdown(5)
    skipBtn.current.classList.remove("BtnSkippEnd")
    skipLineText.current.style.display="none"
    skipBtn.current.style.pointerEvents="none"
      SkipBtnimg.current.style.display="block"
  countDownDiv.current.style.display="flex"
    }
  
  }




 function showSkippBnt(){
  var countdown=6
  console.log("SHOW")
  var btnSkipp=document.getElementById("btnskipp");
  var videoTag=document.getElementById("ad")
  btnSkipp.style.display="flex"
  btnSkipp.style.visibility="visible"

  setTimeout(()=>{btnSkipp.style.opacity="0.9"},1000)
  countwdownInterval=setInterval(function(){
    countdown=countdown-1
    handleCountdown(countdown)

  if(videoTag.paused==true)
  clearInterval(countwdownInterval);
  if(countdown==0){
  clearInterval(countwdownInterval);
  btnSkipp.className="BtnSkippEnd"
  btnSkipp.innerText="Werbung überspringen"
  btnSkipp.style.pointerEvents="auto"
  }
  videoTag.onpause=function(){
  clearInterval(countwdownInterval);
  }
  videoTag.onplay=function(){
  countwdownInterval=setInterval(function(){countdown=countdown-1;
    handleCountdown(countdown)

  if(countdown==0 || countdown<0){
  clearInterval(countwdownInterval);
  btnSkipp.className="BtnSkippEnd"
  btnSkipp.innerText="Werbung überspringen"
  btnSkipp.style.pointerEvents="auto"
  }
  },1000)
  }
  },1000)
  }



  


  const handleChangeVolumemore = (e)=>{
    let VidVolume=parseFloat((e.target.value)/100).toFixed(1)
    

      ad1.current.muted=false;

      ad1.current.volume=VidVolume
    
   

}








const PlayPause = ()=>{
  var advr = document.getElementById("ad")
 
 
 
 if(!videoOn && playerX.getPlayerState()==1){
  playerX.pauseVideo()
 }
 else if(!videoOn && playerX.getPlayerState()==2){
  playerX.playVideo()
 }

 if(advr.paused==true && AdEnded==false && videoOn){
  advr.play()
  setPlay(!isplayed)
  
 }

 else if(advr.paused==false && AdEnded==false && videoOn){
  advr.pause()
  setPlay(!isplayed)
 }


}



   
var youTube=document.getElementById("YouTubeVideo")

async function initYouTubeAPI() {
  if (window['YT'] && window['YT'].Player) {
    createPlayer();
  } else {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window['onYouTubeIframeAPIReady'] = function() {
      createPlayer(); 
    };
  }
}








function createPlayer() {

var YT=window['YT']

player = new window['YT'].Player('player', {
videoId: `${videolist[vidindex].id.videoId}`,
playerVars:{rel:0, autoplay: 1, modestbranding:1, fs:0, playsinline:1, disablekb: 1},
events: {
  'onStateChange': onPlayerStateChange,
   'onReady':onPlayerReady,
   
 
}


});






function onPlayerReady(event) {
  playerX=player
  duration=event.target.getDuration()
   halfDuration=duration/2
   handleObserveContentCurrentTime()
   var index=0  
   var main_video_player=document.getElementById("videoplayer")
   var forwardWindow=document.getElementById("forwardWindow")
   document.getElementById("videolist").style.display="block"
   var timerInterval
   var timer=3


 
  




if(event.target.getDuration()==0)
{
  if(ids_count<emergency_ids.length-1){
    ids_count++
    sessionStorage.setItem('ids_count', ids_count)
    
    const myArrayIDs = sessionStorage.getItem('ids');
    const myArray = JSON.parse(myArrayIDs);

    myArray.forEach((item,index)=>{
      noIds.push(item)
    })

    noIds.push(localStorage.getItem(1))
    const myArraynoIds = JSON.stringify(noIds);

    sessionStorage.setItem('ids',myArraynoIds)

    
  }
  else{
    ids_count=1
    sessionStorage.setItem('ids_count', ids_count)

    const myArrayIDs = sessionStorage.getItem('ids');
    const myArray = JSON.parse(myArrayIDs);

    myArray.forEach((item,index)=>{
      noIds.push(item)
    })

    noIds.push(localStorage.getItem(1))
    const myArraynoIds = JSON.stringify(noIds);

    sessionStorage.setItem('ids',myArraynoIds)


  }
  
  closeAd()
  
  forwardWindow.style.display="block"
  document.getElementById("videolist").style.display="none"
  main_video_player.style.pointerEvents='none'

  main_video_player.style.zIndex="1"
  main_video_player.style.position="absolute"
  document.getElementById("player").style.filter="blur(2px)"
  document.getElementById("info").style.filter="blur(2px)"
  document.getElementById("infochannel").style.filter="blur(2px)"
  timerInterval=setInterval(()=>{
    timer--
 
  
  },1000)
 
  
   setTimeout(() => {
    clearInterval(timerInterval)
   
    document.getElementById("btnskipp").style.display="none"
  
  }, 3000);
  


  

}






}





  function onPlayerStateChange(e) {

    if (e.data == YT.PlayerState.ENDED) {
     VideoEnd=e.data
    }
   
   
  }


}






function nextAd(){
        
   progressBar.current.style.width=0 + "%"
   progressBar.current.style.transition="0s linear"
  resetSkipBtn()
 
 
  
  //ad1.current.removeEventListener('timeupdate', handleTimeUpdate)
 
  ad1.current.style.display="none"
  ad1.current.pause()
  ad1.current.currentTime=0
  ad1.current.remove()
  ad2.current.style.display="flex"
  ad2.current.play()
  ad2played.current=true
  

 
  ad2.current.addEventListener('timeupdate', function(){
    //progressBar.current.style.transition="1s linear"
    videoDuration=Math.round(ad2.current.duration)
    time=Math.round(ad2.current.currentTime)
  
 
    progressBar.current.style.width=(time*100)/videoDuration + "%"
    if(time>=videoDuration){
 
      closeAd()
    }
  })
}



async function initiate(){
await initYouTubeAPI();

}

   
const setAd=()=>{
console.log("setad")
initiate()
    


var adpan = document.getElementById("adpanel")

document.getElementById("videoplayer").addEventListener('mouseover',function(){
  if(videoOn){
    adpan.style.opacity=1
  }
  else{
    adpan.style.opacity=0
  }
})

document.getElementById("videoplayer").addEventListener('mouseleave',function(){
  if(ad1.current.paused===false && videoOn){
    adpan.style.opacity=0
  }
  else if(ad1.current.paused===true && videoOn){
    adpan.style.opacity=1
  }
  
})

    
 
    
   

      ad1.current.style.display="none"

      
      if(isAd2Exists.current){
        ad2.current.style.display="none"
      }
      
      
      
    var dialog=document.getElementById("iosDialog")
    if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
    
     dialog.style.display="flex"
     document.body.style.overflow="hidden"
   
   
 } 

  
        
  }



  async function SetAds(){
    let intervalState
        
    playerX.pauseVideo()
  
  ad1.current.style.display="flex"
  YTplayer.current.style.visibility="hidden"
  if(window.innerWidth<=480 ){
    //YTplayer.current.style.opacity="0"
    YTplayer.current.style.visibility="hidden"
  }

  
    

 


  videoOn=true
  
 // ad1.current.play()
 startAdBlock()

 // adlayout.current.style.display="flex"


  if(skipp==0){
    skipBtn.current.classList.add("midroll-BtnNonSkipp")
    skipBtn.current.style.display="flex"
    skipLineTextNonSkip.current.style.display="block"
     SkipBtnimg.current.style.display="none"
     countDownDiv.current.style.display="none"
  }


  time=0;
  videoDuration=0




 

  if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
   // document.getElementById("iosloadimg").style.display="block"
    let loadInterval
    loadInterval=setInterval(()=>{
      if(document.getElementById("ad").readyState==4){
       // document.getElementById("iosloadimg").style.display="none"
        clearInterval(loadInterval)
      }
    },500)

  }


  if(skipp==1){
  intervalState=setInterval(function(){if(ad1.current.readyState>2){
    clearInterval(intervalState)
 
    skipBtn.current.style.display="flex"
  }
},1000)
}

else{
  
}
playerX.pauseVideo()

    
}


  function handleObserveContentCurrentTime(){
    let current_time_interval=setInterval(()=>{
      var currenttime=playerX.getCurrentTime()
      playerX.onseeked=function(){
        if(!AdEnded && currenttime>=halfDuration){
          console.log("seek")
          SetAds()
          clearInterval(current_time_interval)
        }
      }
       if(currenttime>=halfDuration){
        
        SetAds()
        clearInterval(current_time_interval)
      }
     
      },1000)
  }

  let maxWidth=1920
  let difference=0
  let standardHeight=911
  let differenceHeight=0
  
  const videoSize=(e)=>{
   

      if(e.target.innerWidth<maxWidth){
        difference=maxWidth-e.target.innerWidth
        
        YTplayer.current.style.height=695-(Math.round(difference/2))+"px"
       console.log(difference)

      }
       if(e.target.innerWidth>maxWidth){
        difference=-e.target.innerWidth-maxWidth
      
        YTplayer.current.style.height=695+(Math.round(difference/2))+"px"
       

      }
       if(e.target.innerHeight<standardHeight){
        differenceHeight=standardHeight-e.target.innerHeight
        YTplayer.current.style.height=695-differenceHeight+"px"
        
        console.log(differenceHeight)
      }
      if(e.target.innerHeight>standardHeight){
        differenceHeight=e.target.innerHeight-standardHeight
        YTplayer.current.style.height=695+differenceHeight+"px"
       
        
      }
       
  }

 
  function adjustVideoSize() {
    const baseScreenWidth = 1920; // Максимальная ширина экрана
    const baseScreenHeight = 945; // Максимальная высота экрана
    
    const baseVideoWidth = 1280; // Максимальная ширина видео
    const baseVideoHeight = 720; // Максимальная высота видео
    
    // Текущие размеры экрана
    const currentScreenWidth = window.innerWidth;
    const currentScreenHeight = window.innerHeight;
    
    // Порог уменьшения ширины и высоты видео
    const reductionStartWidth = 1754; 
    const reductionStartHeight = 935;
    
    // Изначально задаём базовые размеры
    let videoWidth = baseVideoWidth;
    let videoHeight = baseVideoHeight;
    
    // Логика уменьшения по ширине
    if (currentScreenWidth < reductionStartWidth) {
        const widthDifference = reductionStartWidth - currentScreenWidth;
        videoWidth = baseVideoWidth - widthDifference;
    
        // Высоту пересчитываем пропорционально ширине
        videoHeight = (videoWidth / baseVideoWidth) * baseVideoHeight;
    }
    
    // Логика уменьшения по высоте
    if (currentScreenHeight < reductionStartHeight) {
        const heightDifference = reductionStartHeight - currentScreenHeight;
        const tempVideoHeight = baseVideoHeight - heightDifference;
    
        // Ширину пересчитываем пропорционально высоте
        const tempVideoWidth = (tempVideoHeight / baseVideoHeight) * baseVideoWidth;
    
        // Берём минимальные значения из расчётов по ширине и высоте
        videoWidth = Math.min(videoWidth, tempVideoWidth);
        videoHeight = Math.min(videoHeight, tempVideoHeight);
    }
    
    // Ограничиваем размеры минимальными значениями
    videoWidth = Math.max(0, Math.min(videoWidth, baseVideoWidth)); 
    videoHeight = Math.max(0, Math.min(videoHeight, baseVideoHeight));
    
    // Применяем размеры ко всем элементам
    YTplayer.current.style.width = `${videoWidth}px`;
    YTplayer.current.style.height = `${videoHeight}px`;
    
    ContentInfo.current.style.width = `${videoWidth}px`;
    ContentInfo.current.style.height = `${videoHeight}px`;
    
    Overlay.current.style.width = `${videoWidth}px`;
    Overlay.current.style.height = `${videoHeight}px`;
    
    Overlay2.current.style.width = `${videoWidth}px`;
    Overlay2.current.style.height = `${videoHeight - 35}px`; // Если нужно
}






      

    useEffect(()=>{

      adjustVideoSize();
window.addEventListener("resize", adjustVideoSize);


      
      adlayout.current.style.display="none"




      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });

      window.matchMedia("(orientation: landscape)").addEventListener("change",e=>{
        const land=e.matches
      
        if(land){
          document.getElementById("videoplayer").style.marginTop=0
          document.getElementById("ad").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("player").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("shield").style.height=parseInt(document.documentElement.clientHeight-57)+"px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','landscape')
          document.body.style.overflow="hidden"
         
        }
        else{
          document.getElementById("ad").style.height="270px"
          document.getElementById("player").style.height="270px"
          document.getElementById("shield").style.height="212px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','portrait')
          document.body.style.overflow="auto"
        }
      })
    
      

     {setAd()}
     
     if(window.innerWidth<=480){
      document.getElementById('nav').style.display="none"
     }
     else{
      document.getElementById('nav').style.display="block"
     }
    
  
     
     //window.addEventListener('focus', tabFocusAd)
   
     //window.addEventListener('blur', tabNoFocusAd)
     
  },[])
 
 

  
const showLoader=()=>{
   
  videoLoader.current.style.display="flex"

}
  
const hideLoader=()=>{
 videoLoader.current.style.display="none" 
 
}
  
      return(
          
 <>
 <Dialog onClick={iosStartVideo}/>
 
          
          <div className="Videoplayer" ref={videoPlayerContainer} id="videoplayer" data-click-tracking="portrait">

    

    <div className='ForwardWindow' id="forwardWindow">
        <img className='ForwardWindowImg' src={Error}/>
        <h2 className='ForwardWindowText' style={{color:'white'}}>Das Video ist nicht verfügbar. <span style={{color:'red'}}> Klicken Sie auf den Button. </span> Sie werden zürück zur Videoauswahl weitergeleitet </h2>
      
       <Link style={{textDecoration:'none'}} to="/youtcheck/pre/?name=1_yRnF-DyBE3dTCs3c4Iei_FfeJT9AsJz&thema=kuchen&s=1">{<div className='Back'>Zurück zur Auswahl</div>}</Link> 

       
         </div>
       
         <div className="Overlay2" ref={Overlay2}></div>

            <div className="Overlay" id="shield" ref={Overlay}>
            
          <div className='Adshell' ref={adlayout} id="adshell">  
         
         
          <div className='loader_container'>
          <CircularProgress ref={videoLoader} className='CircLoad' id="circload" sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);",position:"absolute",left:"45%"}}/>
           </div>

      

          <video ref={ad1}  src={DataAds.ads[adindex].url} className="ad-proceed" id="ad" preload="true" playsInline onTimeUpdate={skipCountDown} onWaiting={showLoader} onCanPlay={hideLoader}></video>

          {isAd2Exists.current ? ( <video ref={ad2} onTimeUpdate={skipCountDown} src={DataAds.ads2[adindex2].url} className="ad-proceed2" id="ad2" preload="true" playsInline onWaiting={showLoader} onCanPlay={hideLoader}> </video>):<></>}
    


          <div className='AdPanel' id="adpanel" >
 
          <div className='videoTrack' id='vidTrack'>
       <div className='Timeline' ref={progressBar}></div>

       </div>
       <Stack spacing={2} id="volumebtn"  direction="row"  alignItems="center">
     
       {isplayed ? <PauseIcon style={{fontSize:25, background:'transparent'}}  id="playbtn" className="btnPlay"></PauseIcon>:<PlayArrowIcon style={{fontSize:30, background:'transparent'}} id="pausebtn" className="btnPlay"></PlayArrowIcon>}
      
      
      <SkipNextIcon style={{fontSize:25, background:'transparent'}}/>
    
      <VolumeUp id="volumeicon"/>


      <Box sx={{ width: 80, display:'flex' }}>
      <Slider  size="small" sx={{ color:'white', height:'4px'}} defaultValue={100} aria-label="Default" onChange={handleChangeVolumemore} />
    </Box>
        
        <div className='SomeIcons'>
          <SubtitlesIcon style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          <SettingsIcon  style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          
          <PictureInPictureAltIcon style={{fontSize:20, marginBottom:2, marginRight:20, background:'transparent'}}/>
          <Crop32Icon  style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          <FullscreenIcon  style={{fontSize:25, background:'transparent'}}/>
        </div>
        </Stack>
        
        

       </div> 

          </div> 

          <div className="midroll-BtnSkipp" id="btnskipp" ref={skipBtn} onClick={closeAdBtnSkipp} variant='contained'>
          <div ref={countDownDiv} className='Countdown'>{countdown}</div>
          <img ref={SkipBtnimg} className='CountdownImg' src={videolist[vidindex].snippet.thumbnails.high.url}/>
          <div ref={skipLineText} id='skip_ad-textline'><div>Überspringen</div> <svg height="100%" viewBox="-6 -6 36 36" width="35px"><path d="M5,18l10-6L5,6V18L5,18z M19,6h-2v12h2V6z" fill="#fff"></path></svg></div>
          <div ref={skipLineTextNonSkip} id='skip_ad-textline_non-skip'>Gleich gehts weiter</div>
          </div>

          </div>
        
        <div className="OverlayCase" ref={YTplayer}>
          <div className="Video" ref={videoPlayer} id="player"></div>
          <div className='Coverlogo'></div>
          </div>
          
         


          <div className='video-info_container' ref={ContentInfo}>
          
          <div className="VideoInfo" id="info">

          <p className="VideoTitle" id="title" >{videolist[vidindex].snippet.title}</p>

          </div>

      
        

          <div className="InfoaboutChannel" id="infochannel">
          <div className='ChannelPanel'>
            <div className='ImgAndAbo'>

              <div className='Container-mobile'>
            
            <div className='Channel-container'><img className="Channel" src={videolist[vidindex].snippet.thumbnails.high.url}></img></div>
           
            <div className="p1">
            <div className='text-container'>{videolist[vidindex].snippet.channelTitle}</div>
            </div>
            
            </div>
 
            <div className='AboDiv'>

             <div className='AboBtn'>Abonnieren</div>
            
             </div>

             </div>

             <div className='ButtonsPanel'>
             <div className='Btn '><div id="btnlike"><ThumbUpAltOutlinedIcon   sx={{fontSize:18}}> </ThumbUpAltOutlinedIcon> <p style={{marginLeft: "10px"}}>{like}</p></div>
             <div><ThumbDownOffAltOutlinedIcon id="btndislike" style={{fontSize:18}}> </ThumbDownOffAltOutlinedIcon></div></div>
            <div className='Teilen'><ReplyIcon id="btnteilen" style={{transform:'scaleX(-1)',fontSize:18}}></ReplyIcon><p>Teilen</p></div>
           
            <div className='Download'><VerticalAlignBottomOutlinedIcon sx={{marginLeft:'16px',fontSize:18}}></VerticalAlignBottomOutlinedIcon><p>Herunterladen</p></div>
            <div className='Clip'><ContentCutOutlinedIcon sx={{marginLeft:'7px',fontSize:18}}></ContentCutOutlinedIcon><p>Clip</p></div>
            <div className='Save'><QueueOutlinedIcon sx={{marginLeft:'12px',fontSize:18}}></QueueOutlinedIcon><p>Speichern</p></div>
            <div className='Punkte'><p>...</p></div>

            </div>
            </div>
             
                <div className="p3">
                  <div id="Comments">Kommentare</div>
                <div className='Descr'>{videolist[vidindex].snippet.description} <span>Mehr</span></div>
                <div className='Comm'><AccountCircleIcon  sx={{fontSize:30, color:'#4487df'}}/> <div id="addCommText">Kommentar hinzufügen</div> </div>
                </div>
              
               
                
            
           
            
            </div>
            </div>
          


            </div>
            </>
      )
      
    
      
      function tabFocusAd(){
       
        ad1.current.play()
      }
    
      function tabNoFocusAd(){
       
        ad1.current.pause()
      }


  
          
    

  function closeAdBtnSkipp(){

    progressBar.current.style.transition="0s linear"
    
    if(!isAd2Exists.current || ad2played.current){
      closeAd()
    }
    else{
      nextAd()
    }
   
    }


 

  function iosStartVideo(){
    document.getElementById("iosDialog").style.display="none"
    playerX.playVideo()
    hideLoader()

 
    document.body.style.overflow="auto"
  }

 

  function closeAd(){

   
    videoOn=false

    AdEnded=true
    var mainplayer=document.getElementById("player")
    var videoTrack=document.getElementById("vidTrack")
  
    var btnSkipp=document.getElementById("btnskipp");
    var volumebtn=document.getElementById("volumebtn")
    var adpanel=document.getElementById("adpanel")
    var adshell=document.getElementById("adshell")
   
    videoTrack.style.display="none"
 
    volumebtn.style.display="none"
    adpanel.style.display="none"
    adshell.style.display="none"

    mainplayer.style.opacity="1"
    mainplayer.style.visibility="visible"
    btnSkipp.style.display="none"
    YTplayer.current.style.visibility="visible"
    
    ad1.current.style.display="none"
    ad1.current.pause()
    ad1.current.remove()

    if(ad2played.current){
    ad2.current.style.display="none"
    ad2.current.pause()
    ad2.current.remove()
    }


    
    playerX.playVideo()

    if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      
      playerX.playVideo()
    }


    
  }


  
}
  



  export default VideoPlayerMid